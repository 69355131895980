/* Opens/closes main navigation panel */

 (function($) {
	$.fn.panelControl = function(options) {

		var zPanel;
		var zControl;

		return this.each(function() {
			zControl = $(this).find('.control button');

			zControl.bind('click', function(event) {
				event.preventDefault();
    		zPanel = $(this).parent('.control').siblings('.content');
        /* Add ARIA */
  			if(zPanel.hasClass('active')) {
  				zPanel.attr('aria-hidden', 'false');
  				zPanel.attr('aria-expanded', 'true');
  			} else {
  				zPanel.attr('aria-hidden', 'true');
  				zPanel.attr('aria-expanded', 'false');
  			}
				zPanel.toggleClass('active');
				$(this).toggleClass('active');
				if(zPanel.hasClass('active')) {
					zPanel.attr('aria-hidden', 'false');
					zPanel.attr('aria-expanded', 'true');
				} else {
					zPanel.attr('aria-hidden', 'true');
					zPanel.attr('aria-expanded', 'false');
				}
			});

			if($('body').hasClass('achat-screen')) {
				$(window).on('resize', function(){
					if($(window).width() > 620) {
						$('.k-panel').each(function(){
							zButton = $(this).find('.control button');;
							zPanel = $('.content', this);
							zPanel.attr('aria-hidden', 'false');
							zPanel.attr('aria-expanded', 'true');
							zPanel.addClass('active');
							$(zButton).removeClass('active');
						});
					}
				});
			}
		});
	}
})(jQuery);
