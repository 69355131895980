/* Based on FlexNav.js 0.3.2
 * Copyright 2013, Jason Weaver http://jasonweaver.name
 * Released under the WTFPL license
 * http://sam.zoy.org/wtfpl/
 *
 * Modified for KAWWA - Feb 02 2013
 * 	Added ARIA support /
 *	Modified variables /
 *	Replaced resize method for jQuery version 1.7
 *
 * Modified for Kawwa - May 2013
 * Added fix to correctly position dropdown if parent's
 * too close to right edge
 * Added missing prevent.default
 *
 * 'breakpoint' is the breakpoint width defined by the @media
 */


(function($) {
	$.fn.flexNav = function(options) {
		var settings = $.extend({
			'breakpoint': '800',
			'animationSpeed': 'fast'
		},
		options);

		var $this = $(this);

		/* Add ARIA roles */
		$this.find('li').attr('role', 'presentation');
    $this.find('a').attr('role', 'menuitem');

    $this.find('li').click(function(event) {
      event.preventDefault();
      $this.removeClass('active');
      $('.underlay').removeClass('active');
  });

		var resizer = function() {
			if ($(window).width() >= settings.breakpoint) {
				$this.addClass('active');
				$('#wrapper').remove('div.underlay');
			} else {
        $this.removeClass('active');
        if ($('#wrapper').find('div.underlay').length === 0) {
          $('#wrapper').prepend('<div class=\"underlay\"></div>');
        }
			}
		};


		// Call once to set.
		resizer();

		// Toggle for nav menu
		$this.siblings('.control').click(function(event) {
				event.preventDefault();
				$this.toggleClass('active');
				$('.underlay').toggleClass('active');
				$('.underlay.active').click(function(event) {
					event.preventDefault();
					$this.removeClass('active');
					$(this).removeClass('active');
				});
		});

		// Call on resize.
		$(window).resize(resizer);

	};

})(jQuery);
