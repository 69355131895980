/* KAWWA TOOLTIP FOR ICON BUTTONS
 * Dependencies: jQuery 1.7 or higher
 * 
 * January 2014
 * 
 * Displays link labels as a tooltip for icon buttons only
 * Works with Kawwa "k-actions" components
 * 
 */
 
 (function($) {
	$.fn.kTitleThat = function(options) {

		return this.each(function() {
			var $this = $(this);
			var zTxt;
			var zPos;
			var zTop;
			var zLeft;
			
			var tooltip = $("<span class=\"tooltip\" role=\"tooltip\"></span>");
			
			$this.bind("focus mouseover", function() {
				zPos = $(this).position();
				zTxt = $(this).text();
				
				tooltip.text(zTxt).appendTo($(this).parent());
				
				zLeft = zPos.left - tooltip.width()/2;
				zTop = zPos.top + $(this).height() + 10;
				
				tooltip.css({"left": zLeft, "top": zTop});
			});
				
			$this.bind("blur mouseout click", function() {
				tooltip.remove();
			});
		});
                
	};
})(jQuery);