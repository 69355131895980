// Le Studio, Atos Worldline, 2016
// Groups all functions to be loaded on page loading
// KAWWA - Version 1.2

var layerAutoComplete = false;
var nbResults = 0;
var nbResultsAutocomplete = 0;

/* Check for detail/summary support */
function nosupportDetails() {
	if (!('open' in document.createElement('details'))) {
		return true;
	}
}

// Loading all scripts ...

window.onload = function(e) {
    
// LANGUAGE ----------------------------------
    $('.language-select').click(function(){
        $(this).toggleClass('open');
    })

    $('.language-select li').click(function(){    
        $('ul li').removeClass('active');
        $(this).toggleClass('active');
    })

// PANEL ----------------------------------

if(jQuery.fn.panelControl) {
	$('.k-panel').panelControl();
}

// TOOLTIPS FOR ICON BUTTONS -------------------------------

	if (jQuery.fn.kTitleThat) {
		jQuery('.show-icons').kTitleThat();
	}


// DROPDOWN ACTIONS -------------------------------

if (jQuery.fn.kDDown) {
		jQuery('.k-dropdown').kDDown();
}

// TABS -------------------------------

	if(jQuery.fn.kTabs) {
		jQuery('.k-tabbed-data').kTabs();
	}
    

// RESPONSIVE NAV -------------------------------

if(jQuery.fn.flexNav) {
		jQuery('.sidenav ul').flexNav({'breakpoint' : '750'});
}
    
    
// SWITCH -------------------------------    
$('.switch').change(function(){
    $(this).toggleClass('checked');
  });
    
    
// QUANTITY -------------------------------     
// var $inputNumber = $('[type=number]') // Plus générique
var $inputNumber = $('[type=number]')

if ($inputNumber.length) {
  // Empêche d'écrire directement dans l'input
  $inputNumber.keypress(function(event) { event.preventDefault() })
  
  $('.button-quantity').click(function(event) {
    event.preventDefault() // Sinon ça submit le form au clic sur le bouton
    var parentButton = $(this).parent();
    var parentTotal = $(this).parent().parent();
    var val = $('input', parentButton).val() // Récupère la valeur de l'input
    // Transforme la chaine de charactère en nb entier sinon en js : "1" + 1 = "11"
    // Mais c'est optionnel dans ce cas là vu que c'est un [type=number] ça récup direct un nombre
    var number = parseInt(val);
    
    // $(this) c'est le bouton sur lequel tu cliques, on peut aussi faire $(event.target)
    if ($(this).data('operator') === 'minus') {
      // Si la nouvelle valeur est inférieur a 1, on sort de la fonction
      if (number-1 < $inputNumber.attr('min')) return
      $('input', parentButton).val(number - 1); // Remplace la valeur de l'input par la précédente - 1
      var value = (number - 1) * 8;
      $('.price', parentTotal).html(value+",00€");
    } else if ($(this).data('operator') === 'plus') {
        $('input', parentButton).val(number + 1); // Remplace la valeur de l'input par la précédente + 1
      var value = (number + 1) * 8;
      $('.price', parentTotal).html(value+",00€");
    }
      
      if ($(this).data('operator') === 'plus') {
          
      }
  })
}  
    
// UPLOAD PHOTO -------------------------------        
var inputAddphoto = '<div class="upload-photo">Photo de profil</div>',
    inputphoto = $('#id_photo');

inputphoto.before(inputAddphoto);

$('.upload-photo').on('click', function() {
    $(this).siblings('#id_photo').trigger('click');
});

inputphoto.on('change', function(){
    var input = $(this),
        reader = new FileReader();

    reader.onload = function (e) {
        input.siblings('.upload-photo').css('background-image', 'url(' + e.target.result + ')');
    };

    reader.readAsDataURL(this.files[0]);
});
    
    
 // ACTIVATION BT -------------------------------          
      $('.button-quantity').click(function(){
            $(".disabled-bt").addClass('highlight-bt');
        });
    
// MODAL ----------------------------------
        $('a.modal').click(function(e){
            $('#modal-exemple').addClass('open');
            e.preventDefault;
        });
        $('#modal-exemple .icon-close').click(function(e){
            $('#modal-exemple').removeClass('open');
            e.preventDefault;
        });

// PANEL AUTOCOMPLETE-----------------------------

function hidePanelAutoComplete(event) {
    $('.search-ticket #panelAuto').attr('aria-hidden','true');
    $('.search-ticket #panelAuto').removeClass();
}

function showPanelAutoComplete(event) {
    $('.search-ticket #panelAuto').attr('aria-hidden','false');
    var classCSS = event.target.classList[0];
    $('.search-ticket #panelAuto').addClass(classCSS);
}

$( '.search-ticket input[type="text"]' ).on({
    focus: function(event) {
        hidePanelAutoComplete(event);
        window.setTimeout(function(){
            showPanelAutoComplete(event);
        },200);
    }, 
    blur: function() {
        if(!layerAutoComplete) {
            window.setTimeout(function() {
                $('.search-ticket #panelAuto').attr('aria-hidden','true');
                var classCSS = $(this).attr('class');
                $('.search-ticket #panelAuto').removeClass(classCSS);
            }, 100);
        }
    }
  });
  $('.search-ticket #panelAuto a').on('click',function(){
    layerAutoComplete = true;
        labelSearch = $(this).text();
        var classCSS = $('#panelAuto').attr('class');
        $('input.'+classCSS).val(labelSearch);
        $('.search-ticket #panelAuto').attr('aria-hidden','true');
        $('.search-ticket #panelAuto').removeClass(classCSS);

        window.setTimeout(function() {
            if(classCSS == 't-from') {
                $('input.t-to').focus();
            } else {
                $('.search-ticket input[type="submit"]').focus();
            }
        },200);
        return false;
});
  $( '.search-ticket input[type="text"]' ).on('keydown',function(event){
    nbResultsAutocomplete = $('#panelAuto ul li').length;
    if(event.keyCode == 40) { // Fleche basse
        nbResults = 0;
        layerAutoComplete = true
        var firstLi = $('#panelAuto ul li').eq(0);
        var firstA = $('a', firstLi);
        $(firstA).focus();

        event.preventDefault();
    } else if(event.keyCode == 16){ // Shift
        layerAutoComplete = false;
        var classCSS = $(this).attr('class');
        $('.search-ticket #panelAuto').removeClass(classCSS);
    }
});
$( '.search-ticket #panelAuto' ).on('keydown',function(event){
    if(event.keyCode == 40) { // Press bottom
        if(nbResults < (nbResultsAutocomplete - 1)) {
            nbResults++;
            var firstLi = $('#panelAuto ul li').eq(nbResults);
            var firstA = $('a', firstLi);
            $(firstA).focus();
        }
        event.preventDefault();
    }
    else if(event.keyCode == 38) { // Press Top
        if(nbResults > 0) {
            nbResults--;
            var firstLi = $('#panelAuto ul li').eq(nbResults);
            var firstA = $('a', firstLi);
            $(firstA).focus();
        }
        
        event.preventDefault();
    }
    else if(event.keyCode == 13 || event.keyCode == 32) { // Press Enter
        var labelSearch = $('#panelAuto ul li').eq(nbResults);
        labelSearch = $('a', labelSearch).text();
        var classCSS = $('#panelAuto').attr('class');
        $('input.'+classCSS).val(labelSearch);
        layerAutoComplete = false;
        $('.search-ticket #panelAuto').attr('aria-hidden','true');
        $('.search-ticket #panelAuto').removeClass(classCSS);

        if(classCSS == 't-from') {
            $('input.t-to').focus();
        } else {
            $('.search-ticket input[type="submit"]').focus();
        }

        event.preventDefault();
    }
});

// PANEL TICKET ----------------------------------
        $('button.panelMentions').click(function(e){
            var parentPanelButton = $(this).parent().parent().parent();
            var parentArticle = $(this).parent().parent().parent().parent();
            var heightPanel = $('.panelTicket', parentPanelButton).height();
            if($(this).attr('aria-expanded') == 'false') {
                var heightPanelMarge = heightPanel + 20;
                $(this).attr('aria-expanded','true');
                $(parentArticle).animate({ marginBottom: '+'+heightPanelMarge }, 100, function(){
                    $('.panelTicket', parentPanelButton).attr('aria-hidden','false');
                });
            }
            else {
                $('.panelTicket', parentPanelButton).attr('aria-hidden','true');
                $(this).attr('aria-expanded','false');
                $(parentArticle).animate({ marginBottom: '2.5rem' }, 100);
            }
            return false;
        });

 // ZAR FORM ----------------------------------

        $('form .icon-raz').click(function(e){
            var form = $(this).parent(form);
            $('input[type="text"]', form).val("");
            return false;
        });

  // AUTOCOMPLETION ----------------------------------
/* 	if (jQuery.fn.extend) {
			var availableTags = [
			"Alsting [Eglise]",
			"Alsting [Relais du Paysan]",
			"Alsting [Rue Châtelaillon]",
			"Alsting [Rue de Palinges]",
			"Forbach [Avenue de Stiring]",
			"Forbach [Avenue Passaga]",
			"Forbach [Avenue Patch]",
			"Forbach [Camille Weiss]",
            "St Avold [Avenue Passaga]",
			"Saarbrücken [Bahnofstrasse]",
			"Saarbrücken [Gutenbergstrasse]",
            "Saarbrücken [Hansahaus]",
			"Forbach [Gare routière]",
			"Forbach [Supermaché]"
			];
			jQuery("#t-from, #t-to").autocomplete({
				source: availableTags
			});
	;} */
    
    
// close
}
